'use client'
import * as React from 'react'
import { cn } from '../../../utils/tailwind-helpers'
import { useCarousel } from './carousel'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<any>>(
  ({ className, children, ...props }, ref) => {
    const { orientation, scrollPrev, canScrollPrev } = useCarousel()

    const classes = cn(
      orientation === 'horizontal'
        ? '-left-12 top-1/2 -translate-y-1/2'
        : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
      className,
    )
    return (
      <React.Fragment>
        {children({ onClick: scrollPrev, ref: ref, disabled: !canScrollPrev, className: classes, ...props })}
        <span className="sr-only">Previous slide</span>
      </React.Fragment>
    )
  },
)

CarouselPrevious.displayName = 'CarouselPrevious'

export default CarouselPrevious
