'use client'

import { FC, useState } from 'react'
import cx from 'clsx'
import FactsHighlight, { FactsHighlights } from '../atoms/fact-highlights'

export interface TabItemProps {
  _key: string
  title: string
  highlights: FactsHighlights[]
}

interface TabProps {
  tab: TabItemProps[]
}

const styles = {
  button:
    'inline-flex justify-center rounded-xl py-2 px-6 text-base font-semibold text-center text-primary border-primary border-2 shadow bg-primary overflow-hidden',
}

const TabContent: FC<TabProps> = ({ tab }) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-col justify-center">
        <div className="flex flex-wrap gap-2">
          {tab.map((item, index) => (
            <button
              key={item._key}
              onClick={() => setActiveTab(index)}
              className={cx(
                styles.button,
                `text-lg mr-2 self-center  ${
                  activeTab === index
                    ? 'text-white font-bold'
                    : 'font-normal bg-transparent text-primary border-primary'
                }`,
              )}
            >
              {item.title}
            </button>
          ))}
        </div>
        {/* Tab Content */}
        <div className="mt-4 md:columns-2">
          {tab[activeTab].highlights.map((item) => (
            <FactsHighlight key={item._key} icon={item.icon} title={item.title} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default TabContent
