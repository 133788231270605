'use client'

import { FC, useEffect } from 'react'

interface MSDestroyScriptProps {
  src: string
}

/**
 * MSScriptDestroy:
 * Helped to remove MSForms from DOM, every time the component is unmounted
 * @params src: (required)
 */

const MSScriptDestroy: FC<MSDestroyScriptProps> = ({ src }) => {
  const loadDynamics = () => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.insertBefore(script, document.body.childNodes[0])

    return () => {
      document.body.removeChild(script)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.MsCrmMkt = undefined
      const iframe = document.querySelector('iframe[src*="dynamics.com"]')
      if (iframe) iframe.remove()
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(loadDynamics, [])

  return null
}

export default MSScriptDestroy
