import { FC, forwardRef, LegacyRef, SyntheticEvent } from 'react'
import clsx from 'clsx'
import Label from './label'
import { ToolTipWarning } from './label-warning'

const styles = {
  textarea: (isError: boolean) =>
    clsx(
      'block w-full rounded-md py-3 px-4 shadow-sm border-primary',
      isError
        ? 'border-red-300 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
        : 'border-gray-300 placeholder-gray-500 focus:border-primary focus:ring-primary',
    ),
  errorMessage: 'mt-2 text-sm text-red-500',
}

interface TextareaProps {
  defaultValue?: string
  errorMessage?: string
  id?: string
  name?: string
  onChange?: (e: SyntheticEvent) => void
  placeholder?: string
  rows?: number
  value?: string
  required?: boolean
  label?: string
}

export const Textarea: FC<TextareaProps> = forwardRef(
  (
    { defaultValue = '', errorMessage, id, name, onChange, placeholder = '', rows = 4, label, ...props },
    ref: LegacyRef<HTMLTextAreaElement>,
  ) => {
    const isError = !!errorMessage
    return (
      <div className="w-full my-2">
        {!!label && (
          <Label
            htmlFor={id}
            srOnly={false}
            className={clsx('mb-1 ml-2 md:text-xl flex items-center', isError ? 'text-red-600' : 'text-text')}
          >
            {label} {isError && <ToolTipWarning error={errorMessage} />}
          </Label>
        )}
        <textarea
          ref={ref}
          className={styles.textarea(isError)}
          defaultValue={defaultValue}
          id={id}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          {...props}
        />
      </div>
    )
  },
)

Textarea.displayName = 'Textarea'
