'use client'
import { Fragment, useState } from 'react'
import cx from 'clsx'
import { CaretDoubleUp } from 'phosphor-react'
import { CaretDoubleDown } from 'phosphor-react-sc'

const styles = {
  childrenDiv: (expanded: boolean) => cx({ 'line-clamp-[12] lg:line-clamp-6': !expanded }),
  button: 'my-10 prose-indigo mx-auto max-w-3xl px-4 md:px-5 text-center cursor-pointer',
  icon: 'text-primary animate-[bounce_2s_infinite] m-auto',
}

const Expandable = ({ children }) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Fragment>
      <div className={styles.childrenDiv(expanded)}>{children}</div>

      <div className={styles.button} onClick={() => setExpanded(!expanded)}>
        {!expanded ? (
          <CaretDoubleDown size={25} className={styles.icon} />
        ) : (
          <CaretDoubleUp size={25} className={styles.icon} />
        )}
      </div>
    </Fragment>
  )
}

export default Expandable
