'use client'

import { FC } from 'react'
import cx from 'clsx'
import { useRouter } from 'next/navigation'

interface OnClickRouterWrapperProps extends React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> {
  As: 'span' | 'div' | 'button'
  children: React.ReactNode
  slug?: string
}

const OnClickRouterWrapper: FC<OnClickRouterWrapperProps> = ({ children, slug, As, className, ...props }) => {
  const router = useRouter()

  if (!slug) {
    return children
  }

  return (
    <As {...props} className={cx(className, 'cursor-pointer')} onClick={() => router.push(slug)}>
      {children}
    </As>
  )
}

export default OnClickRouterWrapper
