import { format, formatDistance } from 'date-fns'
import { compareDesc } from 'date-fns'
import { de } from 'date-fns/locale'

export const formatDate = (date: string | number, useFormat: string) => {
  if (!date) return null
  return format(new Date(date), useFormat, { locale: de })
}

export const formatDiff = (date: string | number) => {
  if (!date) return null
  return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const compareDates = (dateA: string, dateB: string) => {
  const formatStr = 'yyyy-MM-dd'
  return compareDesc(format(dateA, formatStr) as string, format(dateB, formatStr) as string)
}
