import { FC } from 'react'
import clsx from 'clsx'
import { X } from 'phosphor-react-sc'
import { AlertIcon } from './AlertIcon'
import { AlertStatus } from './interface'

const styles = {
  container: (status: AlertStatus) =>
    clsx('rounded-md p-4', status === 'success' && 'bg-green-50', status === 'error' && 'bg-red-50'),
  title: (status: AlertStatus) =>
    clsx('text-sm font-medium', status === 'success' && 'text-green-800', status === 'error' && 'text-red-800'),
  closeBtn: (status: AlertStatus) =>
    clsx(
      'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
      status === 'success' &&
        'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-green-600 focus:ring-offset-green-50',
      status === 'error' && 'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-red-600 focus:ring-offset-red-50',
    ),
}

interface AlertProps {
  className?: string
  onClose?: () => void
  status?: AlertStatus
  title: string
}

export const Alert: FC<AlertProps> = ({ className, onClose, status = 'success', title }) => {
  return (
    <div className={clsx(styles.container(status), className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <AlertIcon status={status} />
        </div>
        <div className="ml-3">
          <p className={styles.title(status)}>{title}</p>
        </div>
        {onClose && (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button type="button" className={styles.closeBtn(status)} onClick={onClose}>
                <span className="sr-only">Dismiss</span>
                <X className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
