'use client'

import React, { FC, useRef, useState } from 'react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { X } from 'phosphor-react'
import { ImageAsset, Section as SectionContent } from '@/types/site.interface'
import useIntersectionObserver from '@react-hook/intersection-observer'
import Section from '../molecules/section'
import ImageElement from './image'
const ReactPlayer = dynamic(() => import('react-player'))

const playerComponents = (player: string) => {
  switch (player) {
    case 'youtube':
      return Youtube
    default:
      return () => null
  }
}

interface VideoPlayerProps {
  player: string
  videoUrl: string
  title: string
  section: SectionContent
  videoPoster: ImageAsset
}

const VideoPlayer: FC<VideoPlayerProps> = ({ player, section, ...props }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)

  if (isIntersecting && !lockRef.current) {
    lockRef.current = true
  }

  const Player = playerComponents(player)
  return (
    <Section section={section} className="mx-none" element="c-video-player" headingClassName="mb-8">
      <div ref={containerRef}>
        {lockRef.current ? (
          <Player {...props} />
        ) : (
          <div className="w-full relative max-w-[100%] aspect-[16/9] mx-auto rounded-2xl md:rounded-element-bg overflow-hidden">
            <div className="absolute inset-0 flex items-center justify-center">
              <ImageElement image={props.videoPoster} alt={props.title} fill />
            </div>
          </div>
        )}
      </div>
    </Section>
  )
}
export default VideoPlayer

interface YoutubeProps {
  videoUrl: string
  title: string
  videoPoster: ImageAsset
}

interface LightBoxPlayerProps {
  videoUrl: string
  title?: string
  isPlaying: boolean
  closeVideo: () => void
}

export const LightBoxPlayer = ({ videoUrl, title, isPlaying, closeVideo }: LightBoxPlayerProps) => {
  const isShort = videoUrl.includes('shorts')
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-2 md:p-6 lg:p-12">
      <button className="absolute top-4 right-4 md:top-8 md:right-8 lg:top-4 text-white text-2xl" onClick={closeVideo}>
        <X size={32} />
      </button>
      <ReactPlayer
        className={clsx('h-full w-full', isShort ? 'aspect-[9/16]' : '')}
        url={videoUrl}
        title={title}
        playing={isPlaying}
        controls
        height="100%"
        width={!isShort ? '100%' : 'auto'}
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,
              mute: 0,
            },
          },
        }}
        onEnded={closeVideo}
      />
    </div>
  )
}

const Youtube: FC<YoutubeProps> = ({ title, videoUrl, videoPoster }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const playVideo = () => {
    setIsPlaying(true)
  }

  const closeVideo = () => {
    setIsPlaying(false)
  }

  return (
    <div className="relative">
      <div className="w-full relative max-w-[100%] aspect-[16/9] mx-auto overflow-hidden rounded-2xl md:rounded-element-bg">
        {videoPoster && <ImageElement image={videoPoster} alt={title} fill />}
        {!videoPoster && <div className="bg-subheading w-full h-full absolute inset-0" />}
      </div>
      {!isPlaying ? (
        <>
          <button
            className="absolute w-24 h-24 flex items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-gray-300 rounded-full p-7"
            onClick={playVideo}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="46" viewBox="0 0 41 46" fill="none">
              <path
                d="M38 17.8038C42 20.1133 42 25.8867 38 28.1962L9.49999 44.6506C5.49999 46.96 0.499998 44.0733 0.499998 39.4545L0.499999 6.54551C0.5 1.92671 5.5 -0.960039 9.5 1.34936L38 17.8038Z"
                fill="white"
              />
            </svg>
          </button>
        </>
      ) : (
        <LightBoxPlayer closeVideo={closeVideo} isPlaying={isPlaying} title={title} videoUrl={videoUrl} />
      )}
    </div>
  )
}
