'use client'

import * as React from 'react'
import { cn } from '../../../utils/tailwind-helpers'
import { useCarousel } from './carousel'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<any>>(
  ({ className, children, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel()

    const classes = cn(
      orientation === 'horizontal'
        ? '-right-12 top-1/2 -translate-y-1/2'
        : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
      className,
    )
    return (
      <React.Fragment>
        {children({ onClick: scrollNext, ref: ref, disabled: !canScrollNext, className: classes, ...props })}
        <span className="sr-only">Next slide</span>
      </React.Fragment>
    )
  },
)
CarouselNext.displayName = 'CarouselNext'

export default CarouselNext
