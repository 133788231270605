// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const isNumber = (value: any): value is number => {
  return typeof value === 'number' && !isNaN(value)
}

export const priceFormatter = (
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  suffix?: string,
) => {
  if (!isNumber(value)) {
    return ''
  }
  const formattedValue = Intl.NumberFormat('de-DE', { minimumFractionDigits, maximumFractionDigits }).format(value)
  return `${formattedValue}${suffix ? ` ${suffix}` : ''}`
}
