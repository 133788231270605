import { FC } from 'react'
import { ImageAsset } from '@/types/site.interface'
import Text from '@ignition/library/components/atoms/text'
import ImageElement from '../elements/image'

export interface FactsHighlights {
  _key?: string
  icon: ImageAsset
  title: string
}

const FactsHighlight: FC<FactsHighlights> = ({ icon, title }) => {
  return (
    <div className="flex items-start py-2 break-inside-avoid">
      <ImageElement
        className="min-w-fit h-auto max-h-6 mt-1"
        imgClassName="h-6 w-auto"
        sizes="8vw"
        image={icon}
        responsive
      />
      <Text as="p" className="px-2 text-lg md:text-xl text-text prose-p:hyphens-auto max-md:break-all">
        {title}
      </Text>
    </div>
  )
}

export default FactsHighlight
