'use client'

import { Fragment } from 'react'
import cx from 'clsx'
import { CaretDown, Check } from 'phosphor-react-sc'
import { Listbox, Transition } from '@headlessui/react'
import LabelWarning from '../label-warning'

export interface DropdownItem {
  [key: string]: string
}

interface DropdownProps {
  items: DropdownItem[]
  selected: DropdownItem
  label?: string
  errorMessage?: string
  onChange: (selected: DropdownItem) => void
}

const styles = {
  listBoxButton: (isError: boolean) =>
    cx(
      { 'text-red-500': isError },
      'relative w-full md:w-5/12 mt-2 md:text-lg cursor-default rounded-xl border border-primary bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:ring-primary focus:outline-none focus:ring-1',
    ),
  selectedSpan: 'block',
  iconWrapper: 'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2',
  listBoxOptions: cx(
    'w-full md:w-5/12 md:text-lg absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
  ),
  listOption: (active: boolean) =>
    cx(active ? 'text-white bg-primary' : 'text-text', 'relative cursor-default select-none py-2 pl-3 pr-9'),
  listOptionSelected: (active: boolean) =>
    cx(active ? 'text-white' : 'text-primary', 'absolute inset-y-0 right-0 flex items-center pr-4'),
}

const Dropdown = ({ items, selected, label, errorMessage, onChange }: DropdownProps) => {
  const isError = !!errorMessage
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <div className={cx('relative mt-1 mb-4 w-full')}>
          <LabelWarning isError={isError} errorMessage={errorMessage} label={label} />
          <Listbox.Button className={styles.listBoxButton(isError)}>
            {selected?.text ? (
              <span className={styles.selectedSpan}>{selected.text}</span>
            ) : (
              <span className={cx(styles.selectedSpan, isError ? 'text-primary' : 'text-text')}>Bitte wählen ...</span>
            )}
            <span className={styles.iconWrapper}>
              <CaretDown className={cx('h-5 w-5 text-primary')} aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={styles.listBoxOptions}>
              {items.map((item, index) => (
                <Listbox.Option key={index} className={({ active }) => styles.listOption(active)} value={item}>
                  {({ selected, active }) => (
                    <>
                      <span className={cx(selected ? 'font-semibold' : 'font-normal', 'block text-start md:text-lg')}>
                        {item?.text}
                      </span>

                      {selected ? (
                        <span className={styles.listOptionSelected(active)}>
                          <Check className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  )
}

export default Dropdown
