import { FC } from 'react'
import clsx from 'clsx'
import { TableItem } from '@/types/site.interface'
import { resolveResponsiveProp } from '@ignition/library/utils/resolve-responsive-prop'

const styles = {
  columns: (columns: number) =>
    clsx('grid  md:justify-items-center', resolveResponsiveProp('grid-cols', { base: 1, md: columns })),
  thHeadCell:
    'first:justify-self-start md:first:ml-3 py-3.5 pl-4 pr-3 text-start md:text-center text-lg md:text-xl font-semibold text-text sm:pl-0',
  tdBody: 'first:justify-self-start',
  dtBody: 'md:hidden text-text font-semibold mr-4',
  ddBody: 'text-lg md:text-xl text-text max-md:flex justify-between',
}

interface TableProps {
  className?: string
  headers: string[]
  items: TableItem[]
}

const Table: FC<TableProps> = ({ className, items, headers }) => {
  return (
    <table className={className}>
      <Thead headers={headers} />
      <Tbody items={items} headers={headers} />
    </table>
  )
}

interface TbodyProps {
  items: TableItem[]
  headers: string[]
}

const Tbody: FC<TbodyProps> = ({ items, headers }) => (
  <tbody className="max-md:divide-y-2">
    {items.map((row) => (
      <tr key={row._key} className={clsx('p-3', styles.columns(row.cells.length))}>
        {row.cells?.map((cell, index) => (
          <td className={styles.tdBody} key={`{cell}-${index}`}>
            <dl>
              <dt className={styles.dtBody}>{headers[index] ?? ''}</dt>
              <dd className={styles.ddBody}>{cell || '-'}</dd>
            </dl>
          </td>
        ))}
      </tr>
    ))}
  </tbody>
)

interface TheadProps {
  headers: string[]
}

const Thead: FC<TheadProps> = ({ headers }) => (
  <thead className="max-md:hidden">
    <tr className={styles.columns(headers.length)}>
      {headers?.map((cell, index) => (
        <th scope="col" key={`{cell}-${index}`} className={styles.thHeadCell}>
          {cell}
        </th>
      ))}
    </tr>
  </thead>
)

export default Table
