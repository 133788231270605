import { FC, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, LegacyRef, SyntheticEvent } from 'react'
import cx from 'clsx'
import Label from './label'
import { ToolTipWarning } from './label-warning'

const styles = {
  input: (isError: boolean) =>
    cx(
      'block py-1 w-full rounded-xl border-2 shadow-sm px-4 border-primary md:text-lg focus:border-primary focus:ring-primary',
      isError ? 'placeholder-red-500 pl-8 text-red-500 border-red-500 focus:border-red-600 focus:ring-red-500' : '',
    ),
  errorMessage: 'mt-2 text-sm text-red-500',
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  autoComplete?: string
  errorMessage?: string
  id?: string
  name: string
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void
  placeholder?: string
  type?: HTMLInputTypeAttribute
  value?: string
  label?: string
}

const Input: FC<InputProps> = forwardRef(
  (
    { autoComplete, errorMessage, id, placeholder, type = 'text', label, className, ...props },
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    const isError = !!errorMessage
    return (
      <div className={className}>
        {!!label && (
          <Label
            htmlFor={id}
            srOnly={false}
            className={cx('mb-1 ml-2 md:text-xl flex items-center', isError ? 'text-red-500' : 'text-text')}
          >
            {label} {isError && <ToolTipWarning error={errorMessage} />}
          </Label>
        )}
        <input
          ref={ref}
          type={type}
          id={id}
          autoComplete={autoComplete}
          className={styles.input(isError)}
          placeholder={placeholder}
          {...props}
        />
      </div>
    )
  },
)

Input.displayName = 'Input'

export default Input
