import { isImageFitCover, Slide, SlideImage, useLightboxProps } from 'yet-another-react-lightbox'
import Lightbox from 'yet-another-react-lightbox'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import { ImageAsset } from '@/types/site.interface'
import { isUndefined } from '@ignition/library/utils/object'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import ImageElement from '../image'

const isSlideImage = (slide: Slide) => {
  return slide && isUndefined(slide?.width) && isUndefined(slide?.height)
}

export const LightBoxImage = ({ slide, rect }) => {
  const { imageFit } = useLightboxProps().carousel
  if (isSlideImage(slide)) return
  const cover = isImageFitCover(slide, imageFit)
  const width = !cover ? Math.round(Math.min(rect.width, (rect.height / slide.height) * slide.width)) : rect.width
  const height = !cover ? Math.round(Math.min(rect.height, (rect.width / slide.width) * slide.height)) : rect.height

  return (
    <div className="relative" style={{ width, height }}>
      <ImageElement fill useFallbackImage image={slide} sizes={`${Math.ceil((width / window.innerWidth) * 100)}vw`} />
    </div>
  )
}

interface LightBoxProps {
  open: boolean
  close: () => void
  slides: ImageAsset[]
  index: number
}

const LightBox = ({ open, close, slides, index = 0 }: LightBoxProps) => {
  const backgroundColor = 'rgba(0, 0, 0, .8)'
  const transparentColor = 'rgba(0, 0, 0, .0)'
  return (
    <Lightbox
      styles={{
        root: { '--yarl__color_backdrop': transparentColor },
        container: { backgroundColor },
        thumbnail: { backgroundColor: transparentColor },
        thumbnailsContainer: { backgroundColor },
      }}
      index={index}
      open={open}
      close={close}
      slides={slides as unknown as SlideImage[]}
      plugins={[Thumbnails]}
      render={{
        slide: LightBoxImage,
        thumbnail: LightBoxImage,
      }}
    />
  )
}

export default LightBox
