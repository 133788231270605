'use client'
import { useEffect, useMemo, useState } from 'react'
import useTranslation from '@/translations/resources'
import { isNumber, priceFormatter } from '@/utils.ts/number'
import { compareDates } from '@ignition/library/utils/format-date'
import { isEmptyObject } from '@ignition/library/utils/object'
import { sortAlphabetically } from './constants'
import { PriceTableItem } from './interface'

const getPriceHeaders = (items: PriceTableItem[]) => {
  const mx = Math.max(...items.map((item) => Object.keys(item.price).length))
  const res = items.filter((item) => Object.keys(item.price).length === mx)
  return res.map((r) => Object.keys(r.price)).flat()
}

const getStates = (items: PriceTableItem[], translate: (term: string) => string) => {
  const classicProgram = translate('priceTable.classicProgram')
  const classicPlusProgram = translate('priceTable.classicPlusProgram')

  const states = [...new Set(items.filter((s) => !isEmptyObject(s.price)).map((x) => x.state))]
    .filter((x) => !!x)
    .sort((a, b) => sortAlphabetically(a, b))
    .map((state) => ({ id: state, text: state }))

  const classicProgramIndex = states.findIndex((x) => x.text === classicProgram)
  if (classicProgramIndex > -1) {
    states.splice(classicProgramIndex, 1)
    states.push({ id: classicProgram, text: classicProgram })
  }
  const classicPlusProgramIndex = states.findIndex((x) => x.text === classicPlusProgram)
  if (classicPlusProgramIndex > -1) {
    states.splice(classicPlusProgramIndex, 1)
    states.push({ id: classicPlusProgram, text: classicPlusProgram })
  }

  return states
}

const germanMonths = [
  { text: 'Januar', value: '01' },
  { text: 'Februar', value: '02' },
  { text: 'März', value: '03' },
  { text: 'April', value: '04' },
  { text: 'Mai', value: '05' },
  { text: 'Juni', value: '06' },
  { text: 'Juli', value: '07' },
  { text: 'August', value: '08' },
  { text: 'September', value: '09' },
  { text: 'Oktober', value: '10' },
  { text: 'November', value: '11' },
  { text: 'Dezember', value: '12' },
]

export const ParserDate = (date: string): string => {
  if (!date) return Date.now().toString()
  const [month, year] = date.split(' ')
  const monthValue = germanMonths.find((x) => x.text.includes(month))?.value || '01'
  return `${year}-${monthValue}-01`
}

const sortByNearest = ({ text: a }, { text: b }) => {
  return compareDates(ParserDate(a), ParserDate(b))
}

const termSort = (items: PriceTableItem[]) => {
  const terms = [...new Set(items.map((x) => x.term))].filter((x) => !!x).map((term) => ({ id: term, text: term }))
  return terms.sort(sortByNearest).reverse()
}

const usePriceTable = ({
  records,
  includeFlights,
  packages,
  tags,
  country,
  selectedState,
  setSelectedState,
  selectedTerm,
  setSelectedTerm,
}) => {
  const translate = useTranslation()
  const [items, setItems] = useState<PriceTableItem[]>([])

  useEffect(() => {
    const items = records
    setSelectedState(
      items.find((item) => tags?.find((tag) => tag.title === item.state))?.state ||
        items.find((x) => !!x.state)?.state ||
        '',
    )
    setSelectedTerm(items.find((x) => !!x.term)?.term || '')
    setItems(items)
  }, [packages, country])

  const table = useMemo(() => {
    const selectedTermItems = items.filter((record) => !selectedTerm || !record.term || selectedTerm === record.term)
    const treatedItems = selectedTermItems.filter(
      (record) => !selectedState || !record.state || selectedState === record.state,
    )

    const headers = [translate('priceTable.headers.schoolName'), ...new Set(getPriceHeaders(treatedItems))]
    const states = getStates(selectedTermItems, translate)
    const terms = termSort(items)
    const districts = [...new Set(items.map((x) => x?.district))].filter((x) => !!x).map((district) => district)

    return {
      headers,
      items: treatedItems,
      states,
      terms,
      districts,
    }
  }, [items, translate, selectedState, selectedTerm])

  const filteredItems = table.items.map(({ id, name, ...entry }) => {
    return {
      _key: id,
      cells: [
        name,
        ...table.headers.slice(1).map((header) => {
          const value = entry.price[header]
          const total = value?.price + (includeFlights ? value?.price_flight : 0)
          return isNumber(total) ? priceFormatter(total, 2, 2, '€') : ''
        }),
      ],
    }
  })

  const districtFiltered = table.districts?.reduce((acc, district) => {
    const itemsDistrict = table.items
      .filter((record) => record?.district === district)
      .map(({ id, name, ...entry }) => {
        return {
          _key: id,
          cells: [
            name,
            ...table.headers.slice(1).map((header) => {
              const value = entry.price[header]
              const total = value?.price + (includeFlights ? value?.price_flight : 0)
              return isNumber(total) ? priceFormatter(total, 2, 2, '€') : ''
            }),
          ],
        }
      })

    if (!itemsDistrict?.length) return acc

    if (!acc) return [{ district, items: itemsDistrict }]

    return [{ district, items: itemsDistrict }, ...acc]
  }, [])

  // Reset selected state if it's not in the list
  useEffect(() => {
    const findState = table.states.find((x) => x.id === selectedState)
    if (!findState) {
      setSelectedState(
        items.find((item) => tags?.find((tag) => tag.title === item.state))?.state ||
          items.find((x) => !!x.state)?.state ||
          '',
      )
    }
  }, [selectedTerm])

  return { ...table, items: filteredItems, districtFiltered }
}

export default usePriceTable
