import { FC } from 'react'
import cx from 'clsx'
import BlockContent from '@/components/blocks/block-content'
import { Block, Blocks, SectionHeadingType } from '@/types/site.interface'
import Text from '@ignition/library/components/atoms/text'

const headingStyle = {
  h1: 'text-4xl sm:text-6xl text-heading',
  h2: 'text-3xl sm:text-5xl text-subheading',
  h3: 'text-2xl sm:text-4xl text-subheading',
  spanH1: 'text-4xl sm:text-6xl text-heading',
  spanH2: 'text-3xl sm:text-5xl text-subheading',
  spanH3: 'text-2xl sm:text-4xl text-subheading',
}

const styles = {
  heading: (headingType: string) =>
    cx(headingStyle[headingType], 'font-bold tracking-tight mb-2 md:mb-6 leading-9 md:leading-11'),
  subHeading: (headingType: string) =>
    cx('text-lg sm:text-2xl font-bold', headingType === 'h1' ? 'text-subheading' : 'text-primary'),
  summary: 'mt-6 max-w-prose text-lg md:text-xl text-gray-500 leading-7 md:leading-8 whitespace-pre-wrap px-0',
}
interface HeadingComponentProps {
  heading?: string
  headingType?: SectionHeadingType
  subheading?: string
}

export const HeadingComponent = ({ heading, subheading, headingType = 'h2' }: HeadingComponentProps) => {
  const headingAs = headingType.includes('span') ? 'span' : headingType
  return (
    <>
      {subheading && (
        <Text as={'h4'} className={styles.subHeading(headingType)}>
          {subheading}
        </Text>
      )}
      {heading && (
        <Text as={headingAs} className={styles.heading(headingType)}>
          {heading}
        </Text>
      )}
    </>
  )
}

interface SectionHeadingProps {
  heading?: string
  headingType?: SectionHeadingType
  subheading?: string
  richSummary?: Blocks
}

const emptyRichText = (richSummary) => {
  if (!richSummary) return true
  return richSummary.some((block: Block) => {
    if (!block?.children || !Array.isArray(block?.children)) return true
    return block.children.find((child: Block) => !child?.text)
  })
}

export const isEmptySection = (section: SectionHeadingProps) =>
  !section?.heading && !section?.subheading && emptyRichText(section?.richSummary)

const SectionHeading: FC<SectionHeadingProps> = ({ heading, headingType, subheading, richSummary }) => {
  const isEmpty = isEmptySection({ heading, subheading, richSummary })

  if (isEmpty) {
    return null
  }

  return (
    <>
      <HeadingComponent heading={heading} subheading={subheading} headingType={headingType} />
      {richSummary && <BlockContent className={styles.summary} blocks={richSummary} />}
    </>
  )
}

export default SectionHeading
