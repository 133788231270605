import { FC } from 'react'
import { WarningCircle } from 'phosphor-react'
import Label from './label'

export const ToolTipWarning = ({ error }) =>
  error && (
    <div className="group ml-1 relative flex justify-center">
      <WarningCircle className="text-red-600" />
      <span className="absolute top-5 z-10 scale-0 transition-all w-max rounded bg-primary p-2 text-base text-white group-hover:scale-100">
        {error}
      </span>
    </div>
  )

interface LabelWarningProps {
  label?: string
  isError?: boolean
  errorMessage?: string
}

const LabelWarning: FC<LabelWarningProps> = ({ label, isError, errorMessage }) => {
  if (!label) return null
  return (
    <Label srOnly={false} className="mb-1 mt-6 text-2xl text-text font-semibold flex items-center">
      {label}
      {` `}
      {isError && <ToolTipWarning error={errorMessage} />}
    </Label>
  )
}
export default LabelWarning
