import React, { FC } from 'react'
import SafeLink from '@/components/atoms/safe-link'
import { ButtonVariant, PageProps } from '@/types/site.interface'
import { isEmptyObject } from '@ignition/library/utils/object'
import { PortableTextMarkComponentProps } from '@portabletext/react'
import { Button } from '../atoms/button'

export interface Link {
  _type: string
  linkType: 'internal' | 'external'
  url?: string
  page?: PageProps
  styles?: { variant: ButtonVariant }
  isButton?: boolean
  title: string
  href: string
  newTab?: boolean
}

interface Props extends PortableTextMarkComponentProps<Link> {
  className?: string
}

const CustomLink: FC<Props> = ({ value, children, text, markKey }) => {
  if (!value || isEmptyObject(value)) return null

  const isExternal = value.linkType === 'external'
  const slug = isExternal ? value?.url || value.href : value.page?.slug
  const newTab = (isExternal && slug && !slug.match('^mailto:|^tel:|#')) || value.newTab ? true : false
  const title = text || children

  return value?.isButton ? (
    <Button className="px-14 mr-8 mb-5" type="anchor" variant={value.styles?.variant} href={slug} newTab={newTab}>
      {title}
    </Button>
  ) : (
    <SafeLink
      id={`${markKey}-${slug}`}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      target={newTab ? '_blank' : undefined}
      href={slug}
      className="hover:underline text-primary"
    >
      {title}
    </SafeLink>
  )
}

export default CustomLink
