import React, { ChangeEvent, FC } from 'react'
import cx from 'clsx'
import Label from '../label'
import LabelWarning from '../label-warning'

const styles = {
  container: (layout: string) => cx('relative items-center', { flex: layout !== 'button' }),
  buttonContainer: 'flex flex-col items-center',
  textContainer: 'text-sm leading-6',
  groupContainer: {
    checkbox: 'space-y-4 grid grid-rows-2 md:grid-flow-col items-end',
    button:
      'gap-4 justify-center w-full max-lg:last:[&>*:nth-child(2n-1)]:col-span-2 grid max-lg:grid-cols-2 lg:grid-flow-col-dense lg:justify-start items-end',
  },
  checkboxButton: {
    checkbox: (color: CheckboxItemColor) =>
      cx(
        'h-4 w-4 mr-3 rounded border-2',
        color === 'primary' && 'text-primary border-primary focus:border-primary focus:ring-primary',
      ),
    button: () => 'hidden',
  },
  labelLayout: {
    checkbox: () => 'text-gray-700 text-lg',
    button: (color: CheckboxItemColor, checked: boolean) =>
      cx(
        '!block py-1 text-center px-6 text-lg border-2 rounded-xl',
        color === 'primary' ? 'text-primary border-primary' : 'text-text border-gray-200',
        checked && 'font-semibold',
      ),
  },
  label: (layout: string, checked?: boolean, color: CheckboxItemColor = 'primary') =>
    cx(styles.labelLayout[layout](color, checked), 'cursor-pointer', {
      'transition-colors': layout === 'button' && checked,
      'bg-primary text-white': layout === 'button' && checked && color === 'primary',
      'bg-gray-200': layout === 'button' && checked && color === 'default',
    }),
  errorMessage: 'mt-2 text-sm text-red-600',
}

type CheckboxItemColor = 'primary' | 'default'

interface CheckboxItemProps {
  checked?: boolean
  id?: string
  name?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  subText?: string
  label: string | JSX.Element
  description?: string
  layout: 'checkbox' | 'button'
  color?: CheckboxItemColor
}

export const CheckboxItem: FC<CheckboxItemProps> = ({
  checked,
  id,
  name,
  onChange,
  label,
  layout,
  color = 'primary',
}) => {
  const inputId = `${id}-${name}`
  return (
    <div className={styles.container(layout)}>
      <div className={styles.buttonContainer}>
        <input
          id={inputId}
          name={name}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          className={cx(styles.checkboxButton[layout](color))}
        />
      </div>
      <div className={styles.textContainer}>
        <Label htmlFor={inputId} className={styles.label(layout, checked, color as CheckboxItemColor)}>
          {label}
        </Label>
      </div>
    </div>
  )
}

interface Option {
  id: string
  text: string
}

interface CheckboxGroupProps {
  errorMessage?: string
  label?: string
  onChange: (selected: string[]) => void
  options: Option[]
  selected: string[]
  name?: string
  layout: 'checkbox' | 'button'
  isMultiSelect?: boolean
  color?: CheckboxItemColor
  className?: string
}

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  name,
  errorMessage,
  label,
  onChange,
  options,
  selected = [],
  layout = 'checkbox',
  isMultiSelect = true,
  color = 'primary',
  className,
}) => {
  const isError = !!errorMessage

  const handleOnChange = (id: string) => {
    if (isMultiSelect) {
      onChange(selected.includes(id) ? selected.filter((x) => x !== id) : [...selected, id])
    } else {
      onChange([id])
    }
  }

  return (
    <div className={cx('w-full mb-2', className)}>
      <LabelWarning isError={isError} errorMessage={errorMessage} label={label} />
      <fieldset className="mt-2">
        <legend className="sr-only">{label}</legend>
        <div className={styles.groupContainer[layout]}>
          {options.map((option) => (
            <CheckboxItem
              layout={layout}
              key={option.id}
              id={option.id}
              checked={selected.includes(option.id)}
              name={name}
              onChange={() => handleOnChange(option.id)}
              label={option.text}
              color={color}
            />
          ))}
        </div>
      </fieldset>
    </div>
  )
}

export default CheckboxGroup
