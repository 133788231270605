'use client'

import React, { FC, FormEvent, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import cx from 'clsx'
import Alert from '@/components/atoms/alert'
import { Button } from '@/components/atoms/button'
import BlockContent from '@/components/blocks/block-content'
import { CheckboxField, FormFieldsProps, InputWidth } from '@/components/elements/dynamic-form/interface'
import Dropdown, { DropdownItem } from '@/components/molecules/form/elements/selectors/dropdown'
import useTranslation from '@/translations/resources'
import { FormData as FormDataProps, FormSubmitStatus } from '@/types/site.interface'
import { Transition } from '@headlessui/react'
import logger from '@ignition/library/lib/logger'
import Input from './elements/input'
import { ToolTipWarning } from './elements/label-warning'
import CheckboxGroup, { CheckboxItem } from './elements/selectors/checkbox'
import { Textarea } from './elements/textarea'
import { Upload } from './elements/upload'

const styles = {
  form: 'flex flex-wrap gap-4',
  submitContainer: 'flex justify-center w-full mt-8',
  contentBlock:
    'max-w-full px-0 w-full text-lg prose-headings:text-text prose-a:text-primary prose-a:no-underline prose-strong:text-primary',
}

const inputWidth = (width?: InputWidth) => {
  switch (width) {
    case '33%':
      return 'w-1/3 flex-[1_0_32.8%]'
    case '50%':
      return 'w-full md:w-1/2 flex-1'
    case '66%':
      return 'w-2/3 flex-[1_0_65.6%]'
    default:
      return 'w-full'
  }
}

interface FormProps {
  defaultValues?: FormDataProps
  formItems: FormFieldsProps[]
  hiddenButton?: boolean
  id?: string
  onSubmit?: (data: FormData, privacyEnabled?: boolean) => Promise<FormSubmitStatus>
  privacyHref?: string
}

const Form: FC<FormProps> = ({ defaultValues = {}, formItems, id }) => {
  const translate = useTranslation()
  const defaultErrMessage = translate('error.form.field.required')
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [statusAlert, setStatusAlert] = useState<FormSubmitStatus>()
  const { control, handleSubmit, formState, register, reset, setValue, trigger, clearErrors } = useForm<FormDataProps>({
    defaultValues,
    shouldUnregister: true,
  })
  const { errors } = formState

  const inputPicker = (item: FormFieldsProps) => {
    if (!item) return null
    const formMessage = item.formMessage || defaultErrMessage
    switch (item?._type) {
      case 'form.email':
        return (
          <Input
            {...register(item._key, {
              required: item.required ? formMessage : false,
              validate: {
                matchPattern: (value) =>
                  item.required
                    ? /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value as string) || defaultErrMessage
                    : true,
              },
            })}
            key={item._key}
            type="email"
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
            className={inputWidth(item.width)}
          />
        )
      case 'form.phone':
        return (
          <Input
            {...register(item._key, {
              required: item.required ? formMessage : false,
            })}
            key={item._key}
            type="tel"
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
            className={inputWidth(item.width)}
          />
        )
      case 'form.textInput':
        return (
          <Input
            {...register(item._key, {
              required: item.required ? formMessage : false,
            })}
            key={item._key}
            placeholder={item.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item.label}
            className={inputWidth(item.width)}
          />
        )
      case 'form.content':
        return !item?.withCheckbox ? (
          <BlockContent key={item._key} blocks={item.content} className={styles.contentBlock} />
        ) : (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            rules={{
              required: item.required ? formMessage : false,
            }}
            render={({ field: { value } }) => (
              <div className="w-full mt-2 flex items-center">
                <CheckboxItem
                  layout="checkbox"
                  name={item._key}
                  label={
                    <BlockContent
                      blocks={item.content}
                      className={cx(styles.contentBlock, 'md:ml-1', { 'text-red-500': !!errors?.[item._key]?.message })}
                    />
                  }
                  checked={value as boolean}
                  onChange={() => {
                    setValue(item._key, !value)
                    clearErrors(item._key)
                  }}
                />
                <ToolTipWarning error={errors?.[item._key]?.message} />
              </div>
            )}
          />
        )
      case 'form.checkbox':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            rules={{
              required: item.required ? formMessage : false,
            }}
            render={({ field: { value } }) => (
              <CheckboxGroup
                {...(item as CheckboxField)}
                name={item._key}
                selected={(value as string[]) || []}
                onChange={(selected) => {
                  setValue(item._key, selected)
                  clearErrors(item._key)
                }}
                options={(item.options && (item.options as string[]).map((x) => ({ text: x, id: x }))) || []}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )
      case 'form.dropdown':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            rules={{
              required: item.required ? formMessage : false,
            }}
            render={({ field: { value } }) => (
              <Dropdown
                selected={(value as DropdownItem) ?? {}}
                onChange={(selected) => {
                  setValue(item._key, selected as DropdownItem)
                  clearErrors(item._key)
                }}
                label={item?.label}
                items={(item.options && (item.options as string[]).map((x) => ({ text: x, id: x }))) || []}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )
      case 'form.uploadFile':
        return (
          <Controller
            key={item._key}
            name={item._key}
            control={control}
            defaultValue={[]}
            rules={{
              required: item.required ? formMessage : false,
            }}
            render={({ field: { value } }) => (
              <Upload
                onChange={(e: React.ChangeEvent<HTMLInputElement> & React.DragEvent<HTMLElement>) => {
                  const fileList = e.target.files || e.dataTransfer.files
                  if (fileList && fileList[0]) {
                    // at least one file has been added
                    setValue(item._key, [...(value as File[]), ...Array.from(fileList)])
                  }
                  trigger(item._key)
                }}
                fileTypes={item.fileTypes}
                name={item._key}
                files={value as File[]}
                onDeleteFile={(fileName: string) => {
                  setValue(
                    item._key,
                    (value as File[]).filter((file) => file.name !== fileName),
                  )
                  trigger(item._key)
                }}
                errorMessage={errors?.[item._key]?.message}
              />
            )}
          />
        )
      case 'form.textArea':
        return (
          <Textarea
            {...register(item?._key, {
              required: item.required ? formMessage : false,
            })}
            key={item?._key}
            placeholder={item?.placeholder}
            errorMessage={errors?.[item._key]?.message}
            label={item?.label}
          />
        )
      default:
        return <div className="hidden" key={item?._key}>{`Unknown field: ${item?._type}`}</div>
    }
  }

  return (
    <form
      id={id}
      action="#"
      method="POST"
      className={styles.form}
      onSubmit={handleSubmit(async (data, evt: FormEvent<HTMLFormElement>) => {
        const form = evt.target as HTMLFormElement
        const formData = new FormData(form)
        // append missing fields into the formData
        const requestData = Object.fromEntries(formData)
        Object.keys(data).forEach((key) => !requestData[key] && formData.append(key, data[key] as string))

        logger.info('Submitting form...', data)
        if (data) {
          //TODO: remove this if statement & setAlert
          setStatusAlert('success')
        }
        setShowAlert(true)
        if (status === 'success') reset()
      })}
    >
      <input className="hidden" name="formId" defaultValue={id} />
      {formItems?.map((item) => inputPicker(item))}
      <Transition show={showAlert} className="overflow-hidden w-full">
        <Transition.Child
          enter="ease-in-out transform duration-[500ms]"
          enterFrom="-translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="ease-out duration-[500ms]"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          <Alert
            status={statusAlert === 'success' ? 'success' : 'error'}
            title={
              statusAlert === 'success'
                ? 'Email wurde erfolgreich Versendet.'
                : 'Wir konnten Ihre E-Mail nicht senden, bitte versuchen Sie es später erneut.'
            }
            onClose={() => setShowAlert(false)}
          />
        </Transition.Child>
      </Transition>
      <div className={cx(styles.submitContainer)}>
        <Button type="button" submit className="w-full">
          kostenlosen Katalog bestellen
        </Button>
      </div>
    </form>
  )
}

export default Form
