import { FC, ReactNode } from 'react'
import cx from 'clsx'
import { Section as ISection } from '@/types/site.interface'
import SectionButton from './section-button'
import SectionContent from './section-content'
import SectionHeading, { isEmptySection } from './section-heading'

const styles = {
  container: 'mx-auto max-w-sm px-4 sm:max-w-lg sm:px-6 lg:max-w-3xl lg:px-8',
  section: (className?: string) => cx(className, 'relative py-4 md:py-6 lg:py-8 prose-headings:hyphens-auto'),
}

interface SectionProps {
  children?: ReactNode
  contentClassName?: string
  headingClassName?: string
  className?: string
  id?: string
  section?: ISection
  element: string
}

const Section: FC<SectionProps> = ({
  children,
  contentClassName,
  headingClassName,
  className,
  id,
  section = { heading: '', headingType: 'h2', subheading: '', summary: '' },
  element,
}) => {
  const { heading, headingType, subheading, richSummary, buttons } = section ?? {}
  return (
    <section id={id} data-element={element} className={styles.section(className)}>
      <SectionContent className={cx(contentClassName)}>
        <div className={cx(headingClassName, { hidden: isEmptySection(section) })}>
          <SectionHeading
            heading={heading}
            headingType={headingType}
            subheading={subheading}
            richSummary={richSummary}
          />
          <SectionButton buttons={buttons} />
        </div>
        {children}
      </SectionContent>
    </section>
  )
}

export default Section
