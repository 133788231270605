import { FC } from 'react'
import { Button } from '@/components/atoms/button'
import { ButtonLink } from '@/types/site.interface'

interface SectionButtonProps {
  buttons?: ButtonLink[]
}

const SectionButton: FC<SectionButtonProps> = ({ buttons }) => {
  if (!buttons?.length) return null
  return (
    <div className="grid grid-flow-col-dense gap-x-6 mt-6 text-center md:justify-start">
      {buttons.map((button) => (
        <Button className="items-center md:justify-self-center max-md:p-2" {...button} key={button._key}>
          {button.title}
        </Button>
      ))}
    </div>
  )
}
export default SectionButton
