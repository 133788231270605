'use client'

import { FC, useEffect } from 'react'
import Script, { ScriptProps } from 'next/script'

interface ScriptDestroyProps extends ScriptProps {
  id: string
  active: boolean
}

/**
 * ScriptDestroy:
 * Helped to remove a next/script from DOM using the id when "active" param is false
 * @params id: string (required)
 * @params active: boolean (required)
 * @params props: ScriptProps (optional)
 */

const ScriptDestroy: FC<ScriptDestroyProps> = ({ active, id, ...props }) => {
  useEffect(() => {
    const script = document.getElementById(id)
    if (!active && script) {
      return script.remove()
    }
  }, [active, id])

  if (!active) {
    return null
  }

  return <Script id={id} {...props} />
}

export default ScriptDestroy
