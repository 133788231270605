'use client'

import { FC } from 'react'
import Text from '@ignition/library/components/atoms/text'
import Section from '../molecules/section'
import TabContent, { TabItemProps } from '../molecules/tabs'

interface ServiceCardProps {
  headline: string
  tabs: TabItemProps[]
}
const ServiceCard: FC<ServiceCardProps> = ({ headline, tabs }) => {
  return (
    <Section element="c-service-card" className="flex flex-col">
      <div className="w-fit px-8 bg-white rounded-tl-xl rounded-tr-xl">
        <Text as="h2" className="text-3xl sm:text-5xl text-subheading font-bold tracking-normal ">
          {headline}
        </Text>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center w-full py-4 px-4 md:px-8 rounded-tr-xl rounded-b-xl bg-white">
        <TabContent tab={tabs} />
      </div>
    </Section>
  )
}

export default ServiceCard
