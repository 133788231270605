'use client'
import { Fragment, useEffect, useState } from 'react'
import cx from 'clsx'
import { format } from 'date-fns'
import useTranslation from '@/translations/resources'
import Toggle from '@ignition/library/components/atoms/toggle'
import useToggle from '@ignition/library/hooks/use-toggle'
import { Button } from '../../atoms/button'
import Table from '../../atoms/table'
import { CheckboxItem } from '../../molecules/form/elements/selectors/checkbox'
import usePriceTable from './use-price-table'

const concatString = (a: string, b: string) => `${a}-${b}`

const PriceTableClient = ({ packages, country, tags, records, tableKey }) => {
  const [includeFlights, toggle] = useToggle(false)
  const [selectedState, setSelectedState] = useState<string>('')
  const [selectedTerm, setSelectedTerm] = useState<string>('')
  const translate = useTranslation()
  const { items, headers, terms, states, districtFiltered } = usePriceTable({
    records,
    includeFlights,
    packages,
    tags,
    country,
    selectedState,
    setSelectedState,
    selectedTerm,
    setSelectedTerm,
  })

  // Set default state if only one available
  useEffect(() => {
    if (states.length === 1) {
      setSelectedState(states[0].id)
    }
  }, [states])

  return (
    <Fragment>
      <div
        className={cx('flex gap-4 flex-col-reverse md:flex-row items-start justify-between', {
          'pl-3 mb-4': states.length > 1,
        })}
      >
        <div className="flex gap-2 sm:gap-4 flex-wrap">
          {states.length > 1 &&
            states.map((x) => (
              <CheckboxItem
                id={concatString(x.id, tableKey)}
                label={x.text}
                key={x.id}
                layout="button"
                checked={selectedState === x.id}
                onChange={() => setSelectedState(x.id)}
              />
            ))}
        </div>
        <div className="flex w-full md:w-fit justify-end">
          <Toggle
            label={translate('priceTable.withFlight')}
            checked={includeFlights}
            onChange={() => toggle()}
            reversed
          />
        </div>
      </div>
      <div className="flex gap-2 sm:gap-4 flex-wrap pl-3">
        {terms.map((x) => (
          <CheckboxItem
            id={concatString(x.id, tableKey)}
            label={x.text}
            key={x.id}
            layout="button"
            color="default"
            checked={selectedTerm === x.id}
            onChange={() => setSelectedTerm(x.id)}
          />
        ))}
      </div>

      {!districtFiltered?.length ? (
        <Table className="min-w-full mt-4" headers={headers} items={items} />
      ) : (
        districtFiltered.map(({ district, items }) => (
          <Fragment key={district}>
            <h3 className="text-2xl font-semibold text-text mt-8 mb-4">{district}</h3>
            <Table className="min-w-full mt-4" headers={headers} items={items} />
          </Fragment>
        ))
      )}

      <div className="flex justify-between items-center mt-4">
        <p className="text-xs pl-3">{`${translate('priceTable.disclaimer')}: ${format(new Date(), 'dd.MM.yyyy')}`}</p>
        <Button href="/kontakt-uva" type="anchor">
          {translate('priceTable.actionButton')}
        </Button>
      </div>
    </Fragment>
  )
}

export default PriceTableClient
