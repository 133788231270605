type Locale = string
type Localization = {
  [key: string]: string
}
type StringMapType = Map<Locale, Localization>

const stringMap: StringMapType = new Map()

stringMap.set('de', {
  'error.form.field.required': 'Bitte füllen Sie dieses Pflichtfeld aus.',
  'priceTable.classicProgram': 'Classic',
  'priceTable.classicPlusProgram': 'Classic Plus',
  'priceTable.withFlight': 'Preise mit Flug',
  'priceTable.disclaimer': 'Alle Preise in Euro.Stand',
  'priceTable.actionButton': 'Unverbindliches Beratungsgespräch',
  'priceTable.headers.schoolName': 'Distrikt/Schule',
  'priceTable.errorMessage':
    'Etwas ist schief gelaufen. Wir konnten die Daten, die Sie suchen, nicht abrufen. Bitte versuchen Sie es später noch einmal',
  'button.readMore': 'Weiterlesen',
  'published.date': 'Veröffentlicht',
  'updated.date': 'Geändert',

  'reading.time': 'Geschätzte Lesezeit',
})

stringMap.set('at', {
  'error.form.field.required': 'Bitte füllen Sie dieses Pflichtfeld aus.',
  'priceTable.classicProgram': 'Classic',
  'priceTable.classicPlusProgram': 'Classic Plus',
  'priceTable.withFlight': 'Preise mit Flug',
  'priceTable.disclaimer': 'Alle Preise in Euro.Stand',
  'priceTable.actionButton': 'Unverbindliches Beratungsgespräch',
  'priceTable.headers.schoolName': 'Distrikt/Schule',
  'priceTable.errorMessage':
    'Etwas ist schief gelaufen. Wir konnten die Daten, die Sie suchen, nicht abrufen. Bitte versuchen Sie es später noch einmal',
  'button.readMore': 'Weiterlesen',
  'published.date': 'veröffentlicht',
  'updated.date': 'Geändert',

  'reading.time': 'Voraussichtliche Lesezeit',
})

stringMap.set('en', {
  'error.form.field.required': 'Please fill in this required field.',
  'priceTable.classicProgram': 'Classic',
  'priceTable.classicPlusProgram': 'Classic Plus',
  'priceTable.withFlight': 'Prices with flight',
  'priceTable.disclaimer': 'All prices in Euro',
  'priceTable.actionButton': 'Non-binding consultation',
  'priceTable.headers.schoolName': 'District/School',
  'priceTable.errorMessage':
    "Something went wrong. We couldn't get the data you are looking for. Please try again later",
  'button.readMore': 'Read more',
  'published.date': 'Published',
  'updated.date': 'Changed',

  'reading.time': 'Estimated reading time',
})

// Enhance this after adding more languages
const useTranslation = (locale?: string) => {
  const currentLocale = locale || 'de'
  const localizations = stringMap.get(currentLocale)

  const translate: (term: string) => string = (term: string) => localizations?.[term] || term
  return translate
}

export default useTranslation
