import useTranslation from '@/translations/resources'
import { AirTablePackage, PriceTableItem, PriceTableRecord, PriceTableRecordFields } from './interface'

export const supportedCountries = [
  'Kanada',
  'USA',
  'Australien',
  'Neuseeland',
  'England',
  'Irland',
  'Spanien',
  'Großbritannien',
]
export const supportedPackages = ['Classic Package', 'Select Package', 'Classic Plus Package']

export const packageMap: { [key: string]: AirTablePackage } = {
  'Classic Package': 'Classic',
  'Classic Plus Package': 'Classic Plus',
  'Select Package': 'Select',
}

const packageTranslation = (packageName: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translation = useTranslation()
  switch (packageName) {
    case 'Classic':
      return translation('priceTable.classicProgram')
    case 'Classic Plus':
      return translation('priceTable.classicPlusProgram')
    default:
      return packageName
  }
}

export const sortAlphabetically = (a: string, b: string) => {
  return a.localeCompare(b, 'en', { numeric: true })
}

export const buildPriceItemsFromRecords = (records: PriceTableRecord[], packages: AirTablePackage[]) => {
  if (!records?.length) {
    return []
  }

  // Filter Classic packages as "state" or if there is not states in records use all packages as state
  const notStates = records.filter((x) => x.fields?.state)
  const filterPackages = (fields: PriceTableRecordFields) => {
    if ((packages.indexOf('Classic') > -1 && fields.package.indexOf('Classic') > -1) || !notStates.length) {
      fields.state = packageTranslation(fields.package)
    }
  }

  // Sort records by price to make sure the lowest price is always first
  const sortRecords = records.sort((a, b) => {
    if (!a.fields?.price || !b.fields?.price) {
      return sortAlphabetically(a.fields?.duration, b.fields?.duration)
    }
    return Number(a.fields?.price) - Number(b.fields?.price)
  })

  const itemsMap: { [key: string]: PriceTableItem } = {}
  sortRecords.forEach((record) => {
    const { id, fields } = record
    // make sure we don't have duplicates in the table
    const key = `${fields.Name}${fields.term}${fields.state}`
    const item = itemsMap[key]

    filterPackages(fields)

    // has an item with the same key, add prices
    if (item) {
      itemsMap[key] = buildPriceItemFromRecord(item, record)
      return
    }

    itemsMap[key] = buildPriceItemFromRecord(
      {
        id,
        name: fields.Name,
        state: fields.state,
        term: fields.term,
        district: fields?.district,
        package: fields.package,
        price: {},
      },
      record,
    )
  })

  return Object.values(itemsMap)
}

const buildPriceItemFromRecord = (base: PriceTableItem, record: PriceTableRecord) => {
  return {
    ...base,
    price: {
      ...base.price,
      ...buildPriceFromRecord(record),
    },
  }
}

const buildPriceFromRecord = (record: PriceTableRecord) => {
  const { fields } = record
  const hasPrice = fields?.price
  return fields.duration && hasPrice
    ? { [fields.duration]: { price: fields.price, price_flight: fields.price_flight } }
    : {}
}
